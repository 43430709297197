<template>
    <v-row>
      <v-col v-if="Window == 'pc'" class="bg-wms-register fill-height"> 
      </v-col>
      <v-col cols="12" md="6" lg="4" align="center">
        <v-card height="100%"> 
          <v-card-text>
            <br/><br/><br/><br/><br/><br/>
            <v-row v>
              <v-col  cols="12" md="2"></v-col>
              <v-col class="py-4" cols="12" align="center">
                <img src="https://packhaistorage.s3.ap-southeast-1.amazonaws.com/image/Oasys+500x100-01.png" width="340px" />
                <h3 class="fontPrompt " style="color: #424242;" > เข้าสู่ระบบ</h3>
              </v-col>
            </v-row>
            <v-form ref="form" class="pt-5" v-model="valid" lazy-validation>
              <v-row >
                <v-col class="pb-2" cols="12" >
                  <v-text-field
                    autofocus
                    height="50px"
                    @keyup.enter="$refs.refpassword.focus()"
                    v-model="username"
                    :rules="[(v) => !!v || 'กรุณากรอก Username ที่ใช้งาน']"
                    required
                    ref="refusername"
                    label="Username"
                    outlined
                    dense
                    prepend-inner-icon="mdi-account"
                  >
                </v-text-field>
                </v-col>
                <v-col class="pb-2" cols="12"  >
                  <v-text-field
                    @keyup.enter="GoToLogin()"
                    v-model="password"
                    height="50px"
                    :rules="[(v) => !!v || 'กรุณากรอก Password ที่ใช้งาน']"
                    required
                    ref="refpassword"
                    label="Password"
                    outlined
                    dense
                    prepend-inner-icon="mdi-lock"
                    :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show_password ? 'text' : 'password'"
                    @click:append="show_password = !show_password"
                  ></v-text-field>
                </v-col>
                <v-col class="pb-2" cols="12">
                  <v-btn
                   :style="{'background-color':warehouse_theme}"
                    class="btn-login"
                    :loading="loading"
                    :disabled="!valid || loading"
                    @click="GoToLogin()"
                  >
                    <span class="fontPrompt">เข้าสู่ระบบ</span>
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </template>
  <style   >
  
  .btn-login{
  
    font-size: 2em !important;
    width:100% !important;
    height:60px !important;
    color: #ffffff !important;
    background: rgb(118,208,200) !important;
  }
  
    @import url('https://fonts.googleapis.com/css2?family=Prompt:wght@500&display=swap');
    
    .fontPrompt {
      font-family: 'Prompt', sans-serif;
      font-size: 20px;
    }
  </style>
  <script>
  import axios from "axios";
  import { header_token } from "@/website/token";
  import { shopService_dotnet, branchService_dotnet } from "@/website/global";
  import { remainDateCheck } from "@/website/global_function";
  import { AlertSuccess, AlertWarning, AlertError } from "@/website/global_alert";
  export default {
  data: () => ({
  warehouse_theme:null,
  valid: true,
  loading: false,
  username: "",
  password: "",
  show_password: false,
  logo: null,
  // size จอ
  size: "lg",
  window: {
    width: 0,
    height: 0,
  },
  // ขนาดจอ
  Window: "pc",
  }),
  async created() {
  window.addEventListener("resize", this.handleResize);
  this.handleResize();
  if (this.logo == null) {
    this.logo =
    "https://packhaistorage.s3.ap-southeast-1.amazonaws.com/image/Oasys+500x100-01.png";
  }
  
  var token = this.$route.query.token;
  if (token == null || token == "" || token == "undefined") {
    var branchId = localStorage.getItem("Branch_BranchID");
    if (branchId != null && branchId != "" && branchId != "undefined") {
      this.$router.push("/dashboard-statistics");
    }
  } else {
    this.username = "";
    this.password = "";
    this.packhaiToken = token;
    await this.GoToLogin();
  }
  },
  watch: {
        size() {
          this.Window = this.size == "xs" || this.size == "sm" ? "mobile" : "pc";
        },
      },
      destroyed() {
        window.removeEventListener("resize", this.handleResize);
      },
  methods: {
  async GoToLogin() {
    this.loading = true;
    let response = await axios.post(
      shopService_dotnet + "StaffBranch/login",
      {
        username: this.username == "" ? null : this.username,
        password: this.password == "" ? null : this.password,
        token: this.packhaiToken,
      },
      { headers: header_token }
    );
    if (response.status == 200) {
      var staffBranch = response.data.staffBranch;
      var staffBranchPermissionItem = response.data.staffBranchPermissionItem;
      var branchPermission = response.data.branchPermission;
      var toDayFirstLogin = response.data.toDayFirstLogin;
      var endSubscriptionDate = response.data.endSubscriptionDate;
      var next = true;
      if (endSubscriptionDate != null) {
        var remaindate = 0;
        remaindate = this.remainDateCheck(endSubscriptionDate);
        if (remaindate <= 0) {
          localStorage.setItem("Branch_BranchID", staffBranch.branchId);
          next = false;
          this.$router.push("/gotopackage");
        }
      }
  
      if (next) {
        if (staffBranch != null) {
          this.AlertSuccess();
          var permission = {};
          for (var i in branchPermission) {
            if (staffBranch.isMasterUser) {
              permission[branchPermission[i].nameEh] = true;
            } else {
              var isActive = staffBranchPermissionItem.filter(
                (x) => x.staffBranchPermissionID == branchPermission[i].id
              );
              if (isActive.length > 0) {
                permission[branchPermission[i].nameEh] = true;
              } else {
                permission[branchPermission[i].nameEh] = false;
              }
            }
          }
  
          localStorage.setItem("permission", JSON.stringify(permission));
          localStorage.setItem("Branch_BranchID", staffBranch.branchId);
          localStorage.setItem("Branch_StaffID", staffBranch.id);
          localStorage.setItem("Branch_StaffName", staffBranch.name);
          localStorage.setItem("Branch_ToDayFirstLogin", toDayFirstLogin ? 1 : 0);
          localStorage.setItem('permission_menu', JSON.stringify(response.data.staffPermissionMenu))
          if (localStorage.getItem("location_href_tmp") == null) {
            this.$router.push("/dashboard-statistics");
          } else {
            window.location = localStorage.getItem("location_href_tmp");
            localStorage.removeItem("location_href_tmp");
          }
        } else {
          this.loading = false;
          this.AlertWarning("Username Password ไม่ถูกต้อง");
        }
      }
    } else {
      this.loading = false;
      this.AlertError();
    }
  },
  handleResize() {
          this.window.width = window.innerWidth;
          this.window.height = window.innerHeight;
          if (this.window.width < 600) {
            this.size = "xs";
          } else if (this.window.width >= 600 && this.window.width < 960) {
            this.size = "sm";
          } else if (this.window.width >= 960 && this.window.width < 1264) {
            this.size = "md";
          } else if (this.window.width >= 1264 && this.window.width < 1904) {
            this.size = "lg";
          } else if (this.window.width >= 1904) {
            this.size = "xl";
          }
        },
  remainDateCheck,
  AlertSuccess,
  AlertWarning,
  AlertError,
  },
  };
  </script>
  